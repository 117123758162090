import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { GlobalContext } from "../../App"
import { getExperiencesAdmin } from "src/apicalls/admin/getExperiencesAdmin"
import Loader from "src/common-components/Loader/Loader"
import MMInput from "src/common-components/MMInput/MMInput"
import ExperienceDisplay from "./ExperienceDisplay"
import styles from "./Admin.module.scss"
import { useDebouncedState } from "src/utils/hooks"
import { MMExperience } from "src/types"
import MMButton from "../../common-components/MMButton/MMButton"
import { createExperienceAdmin } from "../../apicalls/admin/createExperienceAdmin"

const Admin: React.FC = () => {
  const { user } = useContext(GlobalContext)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [experienceList, setExperienceList] = useState<MMExperience[] | null>(null)

  const [searchQuery, updateSignal, setSearchQuery] = useDebouncedState("", 500)

  useEffect(() => {
    if (!loading) {
      updateExperienceList()
    }
  }, [updateSignal])

  useEffect(() => {
    if (user) {
      if (!user.isAdmin) {
        history.replace("/")
      } else {
        setLoading(false)
      }
    }
  }, [user])

  async function updateExperienceList() {
    setExperienceList(null)
    const r = await getExperiencesAdmin(searchQuery)
    if (r.result === "ok") setExperienceList(r.data)
  }

  const createExperience = async () => {
    const res = await createExperienceAdmin()
    setExperienceList(res.data)
  }

  if (loading) return <Loader />
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <MMInput
          label="Keresés program vagy szervező nevében"
          value={searchQuery}
          icon={searchQuery !== "" ? "search" : undefined}
          setValue={setSearchQuery}
        />
        <MMButton theme="callToAction" handleClick={createExperience}>
          Új program
        </MMButton>
      </div>
      {experienceList === null ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.experienceList}>
          {experienceList.length === 0 ? (
            <div className={styles.placeholder}>Nincs a keresésnek megfelelő találat</div>
          ) : (
            experienceList.map((e) => (
              <ExperienceDisplay
                key={e.id}
                experience={e}
                refreshExperiences={updateExperienceList}
              />
            ))
          )}
        </div>
      )}
    </div>
  )
}
export default Admin
