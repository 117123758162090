import { ThemeProvider } from "@material-ui/core"
// TODO: watch out for this bad boi (fixes findDOMNode depr. errors)
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { login } from "./apicalls/login"
import { getUserData } from "./apicalls/getUserData"
import "./App.scss"
import PageBase from "./common-components/PageBase/PageBase"
import ExperienceWrapper from "./pages/Experience/ExperienceWrapper"
import FBAuth from "./pages/FBAuth/FBAuth"
import Home from "./pages/Home/Home"
import Listing from "./pages/Listing/Listing"
import Admin from "./pages/Admin/Admin"
import ProfileWrapper from "./pages/Profile/ProfileWrapper"
import Ratings from "./pages/Ratings/Ratings"
import { User, ConfirmModalParams } from "./types"
import TermsAndConditions from './pages/TermsAndConditions/aszf';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5d6146",
    },
    secondary: {
      main: "#93857c",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "var(--borderRadius)",
      },
      adornedStart: {
        paddingLeft: "calc(1rem - 1px)",
      },
    },
    // MuiInputLabel: {
    //   outlined: {
    //     transform: 'translate(1rem, -6px) scale(0.85)'
    //   },
    //   shrink: {
    //     transform: 'translate(1rem, -6px) scale(0.85)'
    //   }
    // },
  },
})

export const GlobalContext = React.createContext<{
  appLoaded: boolean
  user: User | null
  setUser: (u: User | null) => void
  updateUser: () => void
  numOfGuests: number | null
  searchStart: Date | null
  searchEnd: Date | null
  setNumOfGuests: (v: number | null) => void
  setSearchEnd: (v: Date | null) => void
  setSearchStart: (v: Date | null) => void
  lastCheckedExperience: number
  setLastCheckedExperience: (val: number) => void
  filterLocation: string | null
  filterRadius: number | null
  setFilterLocation: (v: string | null) => void
  setFilterRadius: (v: number | null) => void
  confirmModal?: ConfirmModalParams
  setConfirmModal: (params?: ConfirmModalParams) => void
  showConfirmModal: (open: boolean) => void
}>({
  appLoaded: false,
  user: null,
  setUser: () => {},
  updateUser: () => {},
  numOfGuests: 1,
  searchEnd: null,
  searchStart: null,
  setNumOfGuests: () => {},
  setSearchEnd: () => {},
  setSearchStart: () => {},
  lastCheckedExperience: 0,
  setLastCheckedExperience: () => {},
  filterLocation: "",
  filterRadius: 0,
  setFilterLocation: () => {},
  setFilterRadius: () => {},
  setConfirmModal: () => {},
  showConfirmModal: (open: boolean) => {},
})

function App() {
  const [user, setUser] = useState<User | null>(null)
  const [searchEnd, setSearchEnd] = useState<Date | null>(null)
  const [searchStart, setSearchStart] = useState<Date | null>(() => new Date())
  const [numOfGuests, setNumOfGuests] = useState<number | null>(1)
  const [lastCheckedExperience, setLastCheckedExperience] = useState<number>(0)
  const [filterLocation, setFilterLocation] = useState<string | null>(null)
  const [filterRadius, setFilterRadius] = useState<number | null>(null)
  const [appLoaded, setAppLoaded] = useState<boolean>(false)
  const [confirmModal, setConfirmModal] = useState<ConfirmModalParams>()

  //try to login with cookie
  useEffect(() => {
    if (!user) {
      login()
        .then((res) => {
          if (res.result === "ok") setUser(res.data)
          setAppLoaded(true)
        })
        .catch((e) => {
          setAppLoaded(true)
        })
    }
  }, [user])

  const updateUser = () => {
    getUserData().then((res) => {
      if (res.result === "ok") {
        setUser(res.data)
      }
    })
  }

  const showConfirmModal = (open: boolean) => {
    if (confirmModal) {
      setConfirmModal({ ...confirmModal, open })
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        appLoaded,
        setUser,
        updateUser,
        user,
        searchStart,
        searchEnd,
        numOfGuests,
        setSearchStart,
        setNumOfGuests,
        setSearchEnd,
        lastCheckedExperience,
        setLastCheckedExperience,
        filterLocation,
        filterRadius,
        setFilterLocation,
        setFilterRadius,
        confirmModal,
        setConfirmModal,
        showConfirmModal,
      }}
    >
      <ThemeProvider theme={theme}>
        <PageBase>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/fb">
              <FBAuth />
            </Route>
            <Route path="/experience/:experienceId/">
              <ExperienceWrapper />
            </Route>

            <Route path="/ratings">
              <Ratings />
            </Route>
            <Route exact path="/profile">
              <ProfileWrapper />
            </Route>
            <Route exact path="/listings">
              <Listing />
            </Route>
            <Route exact path="/admin">
              <Admin />
            </Route>
            <Route exact path="/aszf">
              <TermsAndConditions />
            </Route>
            <Redirect to="/" />
          </Switch>
        </PageBase>
      </ThemeProvider>
    </GlobalContext.Provider>
  )
}

export default App
