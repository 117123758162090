import { useMediaQuery } from "@material-ui/core"
import React, { useState, useRef } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import MMButton from "../../common-components/MMButton/MMButton"
import { MMExperience, OwnRating, PastEventData } from "../../types"
import "./carousel.css"
import styles from "./ExperienceDesktop.module.scss"
import ExperienceInfos from "./ExperienceInfos"
import ExperienceRatingsBlock from "./ExperienceRatingsBlock"
import ExperienceTitle from "./ExperienceTitle"
import ExperienceGalleryImage from "./ExperienceGalleryImage"
import OrderDesktopWrapper from "./Order/OrderDesktopWrapper"
import { STATIC_URL } from "src/constants"

interface Props {
  experience: MMExperience
}

const ExperienceDesktop: React.FC<Props> = ({ experience }) => {
  const fourImages = useMediaQuery("(min-width: 900px)")
  const fiveImages = useMediaQuery("(min-width: 1200px)")

  const [carouselVisible, setCarouselVisible] = useState(false)
  const carousel = useRef<Carousel>(null)

  const carouselElements = experience.imageNames.map((img, index) => {
    return <img className={styles.carouselElement} key={index} src={STATIC_URL + img} />
  })

  const handleThumbClick = (index: number) => {
    carousel.current?.moveTo(index, false)
    setCarouselVisible(true)
  }

  return (
    <>
      <div className={styles.container}>
        <ExperienceTitle experience={experience} />
        <div className={styles.imageContainer}>
          <ExperienceGalleryImage
            imageName={experience.imageNames[0]}
            onClick={() => handleThumbClick(0)}
          />
          <ExperienceGalleryImage
            imageName={experience.imageNames[1]}
            onClick={() => handleThumbClick(1)}
          />
          <ExperienceGalleryImage
            imageName={experience.imageNames[2]}
            onClick={() => handleThumbClick(2)}
          />

          {fourImages ? (
            <ExperienceGalleryImage
              imageName={experience.imageNames[3]}
              onClick={() => handleThumbClick(3)}
            />
          ) : null}
          {fiveImages ? (
            <ExperienceGalleryImage
              imageName={experience.imageNames[4]}
              onClick={() => handleThumbClick(4)}
            />
          ) : null}
          <MMButton
            handleClick={() => handleThumbClick(0)}
            className={styles.button}
            icon="collections"
          >
            Galéria
          </MMButton>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.infosComments}>
            <ExperienceInfos experience={experience} />
            <ExperienceRatingsBlock
              numOfRatings={experience.numOfRatings}
              avgScore={experience.avgScore}
            />
          </div>
          <OrderDesktopWrapper experience={experience} />
        </div>
      </div>
      <div className={`${styles.popup} ${carouselVisible ? styles.visible : ""}`}>
        <i
          onClick={() => setCarouselVisible(false)}
          className={`material-icons ${styles.carouselCloseButton}`}
        >
          close
        </i>
        <div className={styles.carouselContainer}>
          <Carousel
            ref={carousel}
            swipeable={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            {carouselElements}
          </Carousel>
        </div>
      </div>
    </>
  )
}

export default ExperienceDesktop
