import { Fade, Modal } from "@material-ui/core"
import React from "react"
import MMButton from "../../../common-components/MMButton/MMButton"
import MMModal from "../../../common-components/MMModal/MMModal"
import styles from "./AuthModal.module.scss"
import Login from "./Login"
import SlideLayout from "../../../common-components/SlideLayout/SlideLayout"
import Register from "./Register"

interface Props {
	open: boolean
	handleClose: () => void
}

const AuthModal: React.FC<Props> = ({ open, handleClose }) => {
	return (
		<MMModal
			handleClose={handleClose}
			title="Bejelentkezés vagy regisztráció"
			open={open}
			fixHeight
		>
			<SlideLayout>
				<Login closeModal={handleClose} />
				<Register closeModal={handleClose}/>
			</SlideLayout>
		</MMModal>
	)
}
export default AuthModal
