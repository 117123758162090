import React, { useContext, useState } from "react"
import { useHistory } from "react-router"
import Loader from "../../common-components/Loader/Loader"
import MMButton from "../../common-components/MMButton/MMButton"
import { usePastEvents, useRatings } from "../../utils/hooks"
import RateModal from "../Profile/Rating/RateModal"
import SingleRating from "../Ratings/SingleRating"
import styles from "./ExperienceRatingsBlock.module.scss"
import { ExperienceContext } from "./ExperienceWrapper"

interface Props {
	avgScore: number
	numOfRatings: number
}

const ExperienceRatingsBlock: React.FC<Props> = ({ avgScore, numOfRatings }) => {
	const [ratings, updateRatings] = useRatings()
	const history = useHistory()

	const [rateModalOpen, setRateModalOpen] = useState(false)

	const [pastEvents, updatePastEvents] = usePastEvents()
	const updateExperience = useContext(ExperienceContext).updateExperience
	const experienceID = window.location.hash.substr(1)
	const relatedPastEvent = pastEvents?.find((e) => e.experienceID === parseInt(experienceID))

	const onChangeRating = () => {
		updateRatings()
		updatePastEvents()
		updateExperience()
	}

	if (!ratings)
		return (
			<div>
				<Loader width="5rem" />
			</div>
		)

	const ratingElements = ratings
		.map((rating, index) => <SingleRating {...rating} key={index} />)
		.slice(0, 5)

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<i className="material-icons">star</i>
				<p>
					{avgScore
						? `${avgScore.toFixed(1)} (${numOfRatings} értékelés)`
						: "Még nem érkezett értékelés"}
				</p>
				{relatedPastEvent?.orderID ? (
					<MMButton
						handleClick={() => setRateModalOpen(true)}
						className={styles.rateButton}
						theme="callToAction"
					>
						{relatedPastEvent?.myRating.score ? "Szerkesztés" : "Értékelés"}
					</MMButton>
				) : null}
			</div>
			{ratingElements}
			{ratingElements.length < ratings.length ? (
				<MMButton
					handleClick={() => history.push({ pathname: "/ratings", hash: experienceID.toString() })}
				>
					Összes értékelés mutatása
				</MMButton>
			) : null}
			{rateModalOpen && relatedPastEvent?.orderID ? (
				<RateModal
					open={rateModalOpen}
					myRating={relatedPastEvent.myRating}
					closeModal={() => setRateModalOpen(false)}
					orderID={relatedPastEvent.orderID}
					onSuccess={onChangeRating}
				/>
			) : null}
		</div>
	)
}
export default ExperienceRatingsBlock
