import { useDebugValue, useEffect, useState } from "react"
import { getPastEvents } from "../apicalls/getPastEvents"
import { getRatings } from "../apicalls/getRatings"
import { useParams } from "react-router-dom"
import { PastEventData, Rating, User } from "../types"
import { GuestOrderNecessaryFields } from "../pages/Experience/Order/FinishOrder/FinishOrderNotLoggedIn"

export const usePastEvents = () => {
  const [pastEvents, setPastEvents] = useState<PastEventData[] | null>(null)

  const updatePastEvents = async () => {
    const res = await getPastEvents()
    if (res) {
      setPastEvents(res.data)
    }
  }

  useEffect(() => {
    updatePastEvents()
  }, [])
  return [pastEvents, updatePastEvents] as const
}

export const useDebouncedState = <T>(defaultValue: T, time: number) => {
  const [value, setValue] = useState<T>(defaultValue)
  const [signal, setSignal] = useState(false)

  useEffect(() => {
    const debounce = setTimeout(() => setSignal(!signal), time)
    return () => clearTimeout(debounce)
  }, [value])

  return [value, signal, setValue] as const
}

export const useRatings = () => {
  const [ratings, setRatings] = useState<Rating[] | null>(null)
  const experienceID = parseInt(useParams<{ experienceId: string }>().experienceId)

  const updateRatings = async () => {
    if (isNaN(experienceID)) return
    const res = await getRatings({ experienceID: experienceID })
    if (res) setRatings(res.data)
  }

  useEffect(() => {
    updateRatings()
  }, [])

  return [ratings, updateRatings] as const
}

export const useBillingFields = (user: User | null, errors?: any) => {
  const b = user?.billingInformation
  const [fullName, setFullName] = useState(user?.fullName || "")
  const [city, setCity] = useState(b?.city || "")
  const [zipCode, setZipCode] = useState(b?.zipCode || "")
  const [addressLine1, setAddressLine1] = useState(b?.addressLine1 || "")
  const [addressLine2, setAddressLine2] = useState(b?.addressLine2 || "")
  const [companyName, setCompanyName] = useState("")
  const [vatNumber, setVatNumber] = useState("")
  const [contactName, setContactName] = useState("")
  const [isCompany, setIsCompany] = useState(false)

  const billingProps = {
    fullName,
    city,
    zipCode,
    addressLine1,
    addressLine2,
    setFullName,
    setCity,
    setZipCode,
    setAddressLine1,
    setAddressLine2,
    errors,
    companyName,
    setCompanyName,
    vatNumber,
    setVatNumber,
    isCompany,
    setIsCompany,
    contactName,
    setContactName,
  }

  return billingProps
}
