import React, { useState, useEffect, useContext } from "react"
import styles from "./ExperienceDisplay.module.scss"
import { MMExperience, MMEvent } from "src/types"
import MMButton from "src/common-components/MMButton/MMButton"
import { ExperienceDataForm } from "./ExperienceDataForm"
import { GlobalContext } from "src/App"
import { Collapse } from "@material-ui/core"
import { getExperienceEventsAdmin } from "src/apicalls/admin/getExperienceEventsAdmin"
import { patchExperienceAdmin } from "src/apicalls/admin/patchExperienceAdmin"
import { deleteExperienceAdmin } from "src/apicalls/admin/deleteExperienceAdmin"
import { STATIC_URL } from "src/constants"
import { PrettyImage } from "src/common-components/PrettyImage/PrettyImage"
import ExperienceImageEditor from "./ExperienceImageEditor"

interface Props {
  experience: MMExperience
  refreshExperiences: () => void
}

const ExperienceDisplay: React.FC<Props> = ({ experience, refreshExperiences }) => {
  const { setConfirmModal } = useContext(GlobalContext)
  const [collapsed, setCollapsed] = useState(true)
  const [formState, setFormState] = useState<MMExperience>(experience)

  async function updateExperience() {
    const r = await patchExperienceAdmin(formState)
    if (r.result === "ok") {
      refreshExperiences()
    } else {
      console.log("Update failed:", r)
    }
  }

  async function deleteExperience() {
    const r = await deleteExperienceAdmin(experience.id)
    if (r.result === "ok") {
      refreshExperiences()
    } else {
      console.log("Deletion failed:", r)
    }
  }

  function onResetForm() {
    setFormState(experience)
  }

  function isFormDirty() {
    return JSON.stringify(formState) !== JSON.stringify(experience)
  }

  function onSave() {
    setConfirmModal({
      open: true,
      title: "Program módosítása",
      text: `Biztosan módosítani szeretné a(z) ${experience.name} (#${experience.id}) programot?`,
      cancelLabel: "Mégse",
      actionLabel: "Módosítás",
      destructive: false,
      action: updateExperience,
    })
  }

  function onDelete() {
    setConfirmModal({
      open: true,
      title: "Program törlése",
      text: `Biztosan végleg törölni kívánja a(z) ${experience.name} (#${experience.id}) programot?`,
      cancelLabel: "Mégse",
      actionLabel: "Törlés",
      destructive: true,
      action: deleteExperience,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.summary}>
        <PrettyImage imageName={experience.mainImageName} className={styles.imgContainer} />

        <div className={styles.names}>
          <h1>
            {experience.name}
            <span className={styles.id}> &mdash; #{experience.id}</span>
          </h1>
          <h2>
            <i className="material-icons">person</i>
            {experience.conductorName}
          </h2>
        </div>
        <MMButton handleClick={() => setCollapsed(!collapsed)}>
          <i className="material-icons">{collapsed ? "expand_more" : "expand_less"}</i>
        </MMButton>
      </div>
      <Collapse in={!collapsed} className={styles.collapse}>
        <div className={styles.content}>
          <ExperienceImageEditor experience={experience} loadImages={!collapsed} />
          <ExperienceDataForm
            loadEvents={!collapsed}
            experience={experience}
            formState={formState}
            setFormState={setFormState}
          />
          <div className={styles.actionContainer}>
            <MMButton handleClick={onDelete}>Program törlése</MMButton>
            <MMButton handleClick={onResetForm} disabled={!isFormDirty()}>
              Alaphelyzet
            </MMButton>
            <MMButton disabled={!isFormDirty()} handleClick={onSave} theme="callToAction">
              Módosítások mentése
            </MMButton>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default ExperienceDisplay
