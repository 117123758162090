import React from 'react';
import { useHistory } from 'react-router';
import styles from './Footer.module.scss'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';

const Footer = () => {
  const history = useHistory()

  const handleTermsAndConditionsClick = () => {
    history.push({ pathname: "/aszf" })
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={[styles.container, styles.flexColumn].join(" ")}>
          <img className={styles.footerLogo} src={require('../../assets/footer_logo.png').default} />
            <div className={styles.iconRow}>
              <a href="https://www.facebook.com/marminalunk.hu" className={styles.footerLogoBox} target="_blank">
                <FacebookRoundedIcon sx={{ color: "#fff", fontSize: 40 }}/>
              </a>
              <a href="https://www.instagram.com/marminalunk.hu" className={styles.footerLogoBox} target="_blank">
                <InstagramIcon sx={{ color: "#fff", fontSize: 40 }} />
              </a>
          </div>
        </div>
        <div className={[styles.container, styles.flexColumn].join(" ")}>
            <CopyToClipboard
              text="info@marminalunk.hu"
              onCopy={() => {toast("Vágolapra másolva")}}
            >
            <p className={styles.footerLink}>info@marminalunk.hu</p>
            </CopyToClipboard>
            <CopyToClipboard
                text="+36305058558"
                onCopy={() => {toast("Vágolapra másolva")}}
            >
              <p className={styles.footerLink}>Dukai Kinga Borbála +36305058558</p>
            </CopyToClipboard>
            <Toaster position="bottom-left"/>
        </div>
        <div className={[styles.container, styles.flexColumn].join(" ")}>
          <div className={[styles.container, styles.flexColumn].join(" ")}>
            <p className={styles.footerLink} onClick={handleTermsAndConditionsClick}>Általános szerződési feltételek</p>
            <p className={styles.footerLink} onClick={handleTermsAndConditionsClick}>GDPR</p>
          </div>
        </div>
        <div className={[styles.container, styles.flexColumn].join(" ")}>
          <div className={styles.container}>
            <a href="https://kh.hu" className={styles.footerLogoBox} target="_blank">
              <img src={require('../../assets/startit_KH_logo.png').default} />
            </a>
            <a href="https://https://www.check-inn.hu" className={styles.footerLogoBox} target="_blank">
              <img src={require('../../assets/check_inn_logo-min.png').default} />
            </a>
            <a href="https://magyarturisztikaiszovetseg.hu/" className={styles.footerLogoBox} target="_blank">
              <img src={require('../../assets/mtsz_logo-min.png').default} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
