import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "../../App"
import MMButton from "../../common-components/MMButton/MMButton"
import MMCheckbox from "../../common-components/MMCheckbox/MMCheckbox"
import MMInput from "../../common-components/MMInput/MMInput"
import { logout } from "../../apicalls/logout"
import styles from "./Preferences.module.scss"
import { BillingInformation, User } from "../../types"
import { patchUser } from "../../apicalls/patchUser"
import { snakeCasefyObject } from "../../utils/misc"

const Preferences: React.FC = () => {
  const { user, updateUser } = useContext(GlobalContext)

  const [isCompany, setIsCompany] = useState(false)
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [fullName, setFullName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [vatNumber, setVatNumber] = useState("")
  const [city, setCity] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [wantNotiEmails, setWantNotiEmails] = useState(false)
  const [wantMiscEmails, setWantMiscEmails] = useState(false)
  const [contactName, setContactName] = useState("")

  const [invalidPassword, setInvalidPassword] = useState(false)

  const { setUser } = useContext(GlobalContext)
  const history = useHistory()

  const autoFill = () => {
    setPassword("")
    setNewPassword("")
    setInvalidPassword(false)
    setIsCompany(false)
    if (user === null) {
      setIsCompany(false)
      setFullName("")
      setCompanyName("")
      setContactName("")
      setVatNumber("")
      setCity("")
      setZipCode("")
      setAddressLine1("")
      setAddressLine2("")
      setWantNotiEmails(false)
      setWantMiscEmails(false)
    } else {
      setFullName(user.fullName)
      setCompanyName(user.billingInformation.companyName || "")
      setContactName(user.billingInformation.contactName || "")
      setVatNumber(user.billingInformation.vatNumber || "")
      setCity(user.billingInformation.city)
      setZipCode(user.billingInformation.zipCode)
      setAddressLine1(user.billingInformation.addressLine1)
      setAddressLine2(user.billingInformation.addressLine2 || "")
      setWantNotiEmails(false)
      setWantMiscEmails(false)
    }
  }

  useEffect(() => {
    autoFill()
  }, [user])

  async function handleLogout() {
    const res = await logout()
    if (res.result === "ok") {
      setUser(null)
      history.replace("/")
    } else {
      console.log("Logout failed:", res.data)
    }
  }

  const updateUserData = async () => {
    setInvalidPassword(false)

    const billingInfo: BillingInformation = {
      vatNumber: isCompany ? vatNumber : null,
      addressLine1,
      addressLine2,
      city,
      companyName: isCompany ? companyName : null,
      contactName: isCompany ? contactName : null,
      zipCode,
    }
    const data = snakeCasefyObject({ ...billingInfo, fullName, oldPassword: password, newPassword })
    try {
      const res = await patchUser(data)
      setUser(res.data)
      updateUser()
    } catch ({ status, response }) {
      if ((response as any).data.data === "invalid credentials") setInvalidPassword(true)
    }
  }

  return (
    <div className={styles.container}>
      {user?.type === "normal" ? (
        <>
          <p className={styles.blockTitle}>Jelszó megváltoztatása</p>
          <div className={styles.inputBlock}>
            <MMInput
              type="password"
              label="Jelszó"
              value={password}
              setValue={setPassword}
              errorText={invalidPassword ? "Helytelen jelszó" : undefined}
            />
            <MMInput
              type="password"
              label="Új jelszó"
              value={newPassword}
              setValue={setNewPassword}
            />
          </div>
        </>
      ) : null}
      <p className={styles.blockTitle}>Számlázási adatok</p>
      <div className={styles.inputBlock}>
        <MMCheckbox value={isCompany} setValue={setIsCompany} label="Cég / egyéni vállalkozó" />
        {!isCompany ? (
          <MMInput label={"Teljes név"} value={fullName} setValue={setFullName} />
        ) : null}
        {isCompany ? (
          <>
            <MMInput label="Kapcsolattartó neve" value={contactName} setValue={setContactName} />
            <MMInput label="Cégnév" value={companyName} setValue={setCompanyName} />
            <MMInput label="Adószám" value={vatNumber} setValue={setVatNumber} />
          </>
        ) : null}
        <div className={styles.rowFlex}>
          <MMInput label="Város" value={city} setValue={setCity} />
          <MMInput label="Irányítószám" value={zipCode} setValue={setZipCode} />
        </div>
        <MMInput label="Utca, házszám" value={addressLine1} setValue={setAddressLine1} />
        <MMInput label="Épület, emelet, ajtó" value={addressLine2} setValue={setAddressLine2} />
      </div>
      <p className={styles.blockTitle}>Értesítési beállítások</p>
      <MMCheckbox
        label="Szeretnék emlékeztető e-mail kapni az eseményeimmel kapcsolatban"
        value={wantNotiEmails}
        setValue={setWantNotiEmails}
      />
      <MMCheckbox
        label="Szeretnék a platformot segítő e-maileket kapni"
        value={wantMiscEmails}
        setValue={setWantMiscEmails}
      />

      <MMButton handleClick={updateUserData} className={styles.saveButton} theme="callToAction">
        Mentés
      </MMButton>
      <MMButton handleClick={handleLogout} className={styles.logoutButton}>
        Kijelentkezés
      </MMButton>
      {user?.isAdmin ? (
        <div className={styles.adminDisplay}>
          <i className={"material-icons"}>info_outline</i>
          Ön adminisztrátori jogokkal rendelkezik
        </div>
      ) : null}
    </div>
  )
}
export default Preferences
