import React, { useState } from "react"
import { useHistory } from "react-router"
import MMButton from "../../common-components/MMButton/MMButton"
import { PastEventData, OwnRating } from "../../types"
import styles from "./PastEvent.module.scss"
import RateModal from "./Rating/RateModal"
import RatingDisplay from "./Rating/RatingDisplay"
import { utcToDateString } from "../../utils/misc"
import { STATIC_URL } from "src/constants"

interface Props extends PastEventData {
  updatePastEvents: () => void
}

const PastEvent: React.FC<Props> = ({
  city,
  date,
  img,
  myRating,
  name,
  experienceID,
  orderID,
  updatePastEvents,
}) => {
  const history = useHistory()

  const [rateModalOpen, setRateModalOpen] = useState(false)

  const openRateModal = () => setRateModalOpen(true)
  return (
    <>
      <div className={[styles.container].join(" ")}>
        <img
          onClick={() => history.push({ pathname: "experience/" + experienceID })}
          src={STATIC_URL + img}
        />
        <div
          onClick={() => history.push({ pathname: "experience/" + experienceID })}
          className={styles.infoBox}
        >
          <p>{name}</p>
          <p>{utcToDateString(date)}</p>
          <p>{city}</p>
        </div>
        <div className={styles.lastSection} onClick={(e) => e.stopPropagation()}>
          {myRating?.score ? (
            <>
              <RatingDisplay score={myRating.score} />
              <i onClick={openRateModal} className={["material-icons", styles.editIcon].join(" ")}>
                edit
              </i>
            </>
          ) : (
            <MMButton handleClick={openRateModal} theme="callToAction">
              Értékeld!
            </MMButton>
          )}
        </div>
      </div>
      <RateModal
        closeModal={() => setRateModalOpen(false)}
        open={rateModalOpen}
        myRating={myRating}
        orderID={orderID}
        onSuccess={updatePastEvents}
      />
    </>
  )
}
export default PastEvent
