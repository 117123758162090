import React from "react"
import { Rating } from "../../types"
import { format } from "date-fns"
import { hu } from "date-fns/locale"
import styles from "./SingleRating.module.scss"
import RatingDisplay from "../Profile/Rating/RatingDisplay"

interface Props extends Rating {}

const SingleRating: React.FC<Props> = ({ comment, score, displayName, date }) => {
	const dateObj = new Date(date * 1000)

	return (
		<div className={styles.container}>
			<div className={styles.flex}>
				<p className={styles.name}>{displayName}</p>
				<RatingDisplay score={score} size="tiny" />
			</div>
			<p className={styles.date}>
				{dateObj.getFullYear() + ", " + format(dateObj, "MMMM", { locale: hu })}
			</p>
			<p className={styles.comment}>{`"${comment}"`}</p>
		</div>
	)
}
export default SingleRating
