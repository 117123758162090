import React, { useContext, useEffect, useState } from "react"
import Scrollbars from "react-custom-scrollbars-2"
import { getEvents } from "../../../apicalls/getEvents"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import { MMEvent, MMExperience } from "../../../types"
import BasicSearchFields from "./BasicSearchFields"
import styles from "./ChooseDate.module.scss"
import OrderEvent from "./OrderEvent"

interface Props {
	experience: MMExperience
	isOpen: boolean
	setIsOpen: (v: boolean) => void
	setTransformed?: (val: boolean) => void
	setSelectedEvent: (val: MMEvent) => void
}

const ChooseDate: React.FC<Props> = ({
	experience,
	setIsOpen,
	isOpen,
	setTransformed,
	setSelectedEvent,
}) => {
	const { numOfGuests, searchEnd, searchStart } = useContext(GlobalContext)

	const [events, setEvents] = useState<MMEvent[]>([])

	useEffect(() => {
		const setEventsToState = async () => {
			const res = await getEvents({
				endTime: searchEnd ? Math.round(searchEnd.getTime() / 1000) : null,
				startTime: searchStart ? Math.round(searchStart.getTime() / 1000) : null,
				numOfGuests: numOfGuests || 0,
				experienceID: experience.id,
			})
			setEvents(res.data || [])
		}
		setEventsToState()
	}, [searchStart, searchEnd, numOfGuests])

	const eventsToDisplay = isOpen ? events : events.slice(0, 3)

	const onSelectClick = (event: MMEvent) => {
		setTransformed?.(true)
		//if (!isOpen) setIsOpen(true)
		setSelectedEvent(event)
	}

	const eventDisplayElements = eventsToDisplay.map((event, i) => {
		return (
			<OrderEvent
				onSelectClick={() => onSelectClick(event)}
				event={event}
				experience={experience}
				key={i}
			/>
		)
	})

	return (
		<div className={styles.container}>
			{isOpen ? (
				<div className={styles.title}>
					<div className={styles.top}>
						<p>Időpontválasztás</p>
					</div>
					<BasicSearchFields />
				</div>
			) : null}
			{isOpen ? null : <BasicSearchFields />}
			{events.length === 0 ? (
				<p className={styles.noEventsInfo}>Nincsenek események a megadott kritériumokkal</p>
			) : (
				<Scrollbars>
					<div className={[styles.eventContainer, styles.horizontalPadding].join(" ")}>
						{eventDisplayElements}
					</div>
				</Scrollbars>
			)}
			{!isOpen && events.length > eventsToDisplay.length ? (
				<MMButton handleClick={() => setIsOpen(!isOpen)} className={styles.button}>
					További dátumok mutatása
				</MMButton>
			) : null}
		</div>
	)
}
export default ChooseDate
