import React, { useState, useEffect, useContext } from "react"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { MMEvent } from "src/types"
import MMButton from "src/common-components/MMButton/MMButton"
import MMInput from "src/common-components/MMInput/MMInput"
import { downloadEventOrdersAdmin } from "src/apicalls/admin/downloadEventOrdersAdmin"
import MMTooltip from "src/common-components/MMTooltip/MMTooltip"
import { patchEventAdmin } from "src/apicalls/admin/patchEventAdmin"
import { GlobalContext } from "src/App"
import styles from "./EventDisplay.module.scss"
import { deleteEventAdmin } from "src/apicalls/admin/deleteEventAdmin"

interface Props {
  event: MMEvent
  updateEventList: () => void
  editable?: boolean
}

const EventDisplay: React.FC<Props> = ({ event, editable, updateEventList }) => {
  const [eventDate, setEventDate] = useState<Date>(new Date(event.startTime * 1000))
  const [maxPeople, setMaxPeople] = useState(event.maxPeople)
  const { setConfirmModal } = useContext(GlobalContext)

  useEffect(() => {
    const newStartTime = eventDate.valueOf() / 1000
    if (newStartTime !== event.startTime) {
      event.startTime = newStartTime
      patchEvent()
    }
  }, [eventDate])

  async function patchEvent() {
    const res = await patchEventAdmin(event)
    updateEventList()
  }

  async function deleteEvent() {
    const res = await deleteEventAdmin(event)
    updateEventList()
  }

  async function onDownloadOrderList() {
    await downloadEventOrdersAdmin(event.id)
  }

  function onDelete() {
    if (event.occupiedSlots === 0) {
      setConfirmModal({
        open: true,
        title: "Esemény módosítása",
        text: `Biztosan végleg törölni kívánja a kiválasztott eseményt?`,
        cancelLabel: "Mégse",
        actionLabel: "Törlés",
        destructive: true,
        action: deleteEvent,
      })
    } else {
      setConfirmModal({
        open: true,
        title: "Esemény törlése",
        text: `A módosítani kívánt eseményhez megrendelések tartoznak. Az esemény törlése után értesítse a megrendelőket a változásokról! A letölthető rendelési lista tartalmazza minden megrendelő elérhetőségét.`,
        cancelLabel: "Mégse",
        actionLabel: "Törlés",
        destructive: true,
        action: deleteEvent,
      })
    }
  }

  function onDateChanged(date: Date) {
    if (event.occupiedSlots === 0) {
      setEventDate(date)
    } else {
      setConfirmModal({
        open: true,
        title: "Esemény módosítása",
        text: `A módosítani kívánt eseményhez megrendelések tartoznak. Módosítás után értesítse a megrendelőket a változásokról! A letölthető rendelési lista tartalmazza minden megrendelő elérhetőségét.`,
        cancelLabel: "Mégse",
        actionLabel: "Módosítás",
        destructive: false,
        action: () => setEventDate(date),
      })
    }
  }

  function onMaxPeopleEdited() {
    if (event.maxPeople === maxPeople) return
    if (event.occupiedSlots === 0) {
      event.maxPeople = maxPeople
      patchEvent()
    } else {
      setConfirmModal({
        open: true,
        title: "Esemény módosítása",
        text: `A módosítani kívánt eseményhez megrendelések tartoznak. Módosítás után értesítse a megrendelőket a változásokról! A letölthető rendelési lista tartalmazza minden megrendelő elérhetőségét.`,
        cancelLabel: "Mégse",
        actionLabel: "Módosítás",
        destructive: false,
        action: () => {
          event.maxPeople = maxPeople
          patchEvent()
        },
        onCancel: () => setMaxPeople(event.maxPeople),
      })
    }
  }

  return (
    <div className={styles.eventDisplay}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          className={styles.calendarInput}
          label="Időpont"
          inputVariant="outlined"
          value={eventDate}
          color="secondary"
          ampm={false}
          disableToolbar
          onChange={(date) => date && onDateChanged(date)}
          inputProps={{
            value: eventDate.toLocaleString("hu", { dateStyle: "medium", timeStyle: "short" }),
          }}
          disablePast={editable}
          disabled={!editable}
        />
      </MuiPickersUtilsProvider>

      <span className={[styles.label, styles.small].join(" ")}>
        <MMInput
          label="Maximális létszám"
          value={maxPeople?.toString() || ""}
          onBlur={onMaxPeopleEdited}
          disabled={!editable}
          setValue={(v) => {
            const val = parseInt(v)
            if (isNaN(val)) {
              setMaxPeople(0)
            } else {
              setMaxPeople(val)
            }
          }}
        />
      </span>

      <MMTooltip arrow placement="top" title="Lefoglalt helyek">
        <span className={styles.label}>
          <i className="material-icons">people</i> {event.occupiedSlots} / {event.maxPeople}
        </span>
      </MMTooltip>

      <span className={styles.spacer} />
      <MMButton handleClick={onDelete} className={styles.button} icon="close">
        Törlés
      </MMButton>
      <MMButton
        disabled={event.occupiedSlots === 0}
        handleClick={onDownloadOrderList}
        className={styles.button}
        icon="download"
      >
        Rendelések
      </MMButton>
    </div>
  )
}

export default EventDisplay
