import { Button, useMediaQuery } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router"
import { GlobalContext } from "../../App"
import AuthModal from "../../pages/Home/AuthModal/AuthModal"
import MMButton from "../MMButton/MMButton"
import MMModal from '../MMModal/MMModal'
import MMTooltip from "../MMTooltip/MMTooltip"
import styles from "./Header.module.scss"
import SearchPopup from "./SearchPopup"

const Header: React.FC = () => {
  const { user, setSearchStart, setNumOfGuests, setSearchEnd } = useContext(GlobalContext)
  const history = useHistory()

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
  const [isCraftsmanModalOpen, setIsCraftsmanModalOpen] = useState(false)

  const isMobile = useMediaQuery("(max-width: 600px)")

  const handleProfileClick = () => {
    if (user) history.push({ pathname: "/profile" })
    else setIsAuthModalOpen((s) => !s)
  }

  const handleHomeClick = () => {
    setSearchEnd(null)
    setSearchStart(null)
    setNumOfGuests(1)
    history.push({ pathname: "/" })
  }

  const onSearchClick = () => {
    history.push({ pathname: "/listings" })
    setIsSearchModalOpen(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <img
          draggable={false}
          src="/logo/mm-logo-color.svg"
          onClick={handleHomeClick}
          className={styles.logo}
        />
      </div>
      {isMobile ? (
        <div onClick={onSearchClick} className={[styles.mobileSearch].join(" ")}>
          <i className={"material-icons"}>search</i>
          <p>Keresés</p>
        </div>
      ) : (
        <div className={[styles.box, styles.searchButton].join(" ")}>
          <MMButton handleClick={onSearchClick} icon="search">
            {"Keresés vagy szűrés a programokban"}
          </MMButton>
        </div>
      )}
      {!isMobile && user?.isAdmin ? (
        <MMButton
          handleClick={() => {
            history.push("/admin")
          }}
          icon="vpn_key"
        >
          {"Admin"}
        </MMButton>
      ) : null}
      <div className={[styles.box, styles.account].join(" ")}>
        {!user && (<div className={styles.clickable}>
          <Button
            onClick={() => {
              setIsCraftsmanModalOpen(true);
            }}
            className={styles.btnCraftsmanLogin}
          >
            Jelentkezés kézművesként
          </Button>
        </div>)
        }
        <div onClick={handleProfileClick}  className={styles.clickable}>
          {user && !isMobile ? <p>{user.fullName}</p> : null}
          <div className={[styles.iconContainer, user ? "" : styles.notLoggedIn].join(" ")}>
            <i className="material-icons">person</i>
          </div>
        </div>
      </div>
      <MMModal
        handleClose={() => setIsCraftsmanModalOpen(false)}
        title="Köszönjük érdeklődését!"
        open={isCraftsmanModalOpen}
      >
        <div style={{textAlign: 'center'}}>
          <p>A kézműves felületünk még fejlesztés alatt áll, várhatóan egy hónap múlva lesz használható honlapunk.</p><br />
          <p>Keressük az érdeklődő kézműveseket, így jelentkezését szívesen fogadjuk az <a href="mailto:info@marminalunk.hu">info@marminalunk.hu</a>-n.</p><br />
          <br />
          <p>A Márminálunk csapata</p>
        </div>
      </MMModal>
      <AuthModal handleClose={() => setIsAuthModalOpen(false)} open={isAuthModalOpen} />
      <SearchPopup onClose={() => setIsSearchModalOpen(false)} open={isSearchModalOpen} />
    </div>
  )
}

export default Header
