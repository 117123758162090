import React, { useState } from "react"
import { patchRating } from "../../../apicalls/patchRating"
import MMButton from "../../../common-components/MMButton/MMButton"
import MMInput from "../../../common-components/MMInput/MMInput"
import MMModal from "../../../common-components/MMModal/MMModal"
import { OwnRating } from "../../../types"
import styles from "./RateModal.module.scss"
import RatingDisplay from "./RatingDisplay"

interface Props {
	myRating: OwnRating | null
	open: boolean
	closeModal: () => void
	orderID: number
	onSuccess?: () => void
}

const RateModal: React.FC<Props> = ({ open, closeModal, myRating, orderID, onSuccess }) => {
	const [score, setScore] = useState(myRating?.score || 0)
	const [comment, setComment] = useState(myRating?.comment || "")

	const updateOwnRating = async () => {
		try {
			const res = await patchRating({ comment, score, orderID })
		} catch (err) {
			console.log(err)
		}
		onSuccess?.()
		closeModal()
	}

	return (
		<MMModal handleClose={closeModal} open={open} title="Értékelés">
			<div className={styles.container}>
				<div className={styles.starsContainer}>
					<RatingDisplay size="big" score={score} setScore={setScore} />
				</div>
				<MMInput rows={6} value={comment} setValue={setComment} label="Szöveges értékelés" />
				<MMButton handleClick={updateOwnRating} theme="callToAction" className={styles.button}>
					Mentés
				</MMButton>
			</div>
		</MMModal>
	)
}
export default RateModal
