import React, { useState, useEffect, useContext } from "react"
import styles from "./ExperienceImageEditor.module.scss"
import { MMExperience, MMEvent, ExperienceImage, ExperienceImageMetadata } from "src/types"
import MMButton from "src/common-components/MMButton/MMButton"
import { GlobalContext } from "src/App"
import { PrettyImage } from "src/common-components/PrettyImage/PrettyImage"
import { getExperienceImagesAdmin } from "src/apicalls/admin/getExperienceImagesAdmin"
import { deleteExperienceImageAdmin } from "src/apicalls/admin/deleteExperienceImageAdmin"
import { createExperienceImageAdmin } from "src/apicalls/admin/createExperienceImageAdmin"
import { useMediaQuery } from "@material-ui/core"

interface Props {
  experience: MMExperience
  loadImages: boolean
}

const ExperienceImageEditor: React.FC<Props> = ({ experience, loadImages }) => {
  const { setConfirmModal } = useContext(GlobalContext)
  const [images, setImages] = useState<ExperienceImage[]>([])
  const isLarge = useMediaQuery("(min-width: 1900px)")

  const allowedFileExtensions = ".jpg,.jpeg,.png"

  useEffect(() => {
    if (loadImages && images.length === 0) {
      loadImageList()
    }
  }, [loadImages])

  async function loadImageList() {
    const r = await getExperienceImagesAdmin(experience.id)
    if (r.result === "ok") setImages(r.data)
  }

  async function deleteImage(id: number) {
    const r = await deleteExperienceImageAdmin(id)
    if (r.result === "ok") loadImageList()
  }

  function onDelete(img: ExperienceImage) {
    setConfirmModal({
      open: true,
      title: "Kép törlése",
      text: `Biztosan végleg törölni kívánja a következő képet: '${img.filename}'?`,
      cancelLabel: "Mégse",
      actionLabel: "Törlés",
      destructive: true,
      action: () => deleteImage(img.id),
    })
  }

  function onUploadClick() {
    document.getElementById("mm-admin-image-upload" + experience.id)?.click()
  }

  async function onUploadFileSelected(file: File) {
    if (!file) return

    // TODO: No need for this as server side compression is now live
    // if (file.size > 2 * 1024 * 1024) {
    //   alert("Túl nagy fájl (>1MB)")
    //   return
    // }

    const metadata: ExperienceImageMetadata = {
      filename: file.name,
      main: images.length === 0,
      ordinalNumber: images.length,
      description: null,
    }
    const r = await createExperienceImageAdmin(experience.id, metadata, file)
    if (r.result === "ok") loadImageList()
  }

  return (
    <div className={styles.content}>
      <h1 className={styles.blockTitle}>Képek</h1>
      <div className={styles.block}>
        {images.map((img, idx) => (
          <div
            key={"img-" + idx}
            className={[styles.imageWrapper, isLarge ? styles.large : null].join(" ")}
          >
            <PrettyImage imageName={img.filename} className={styles.image} />
            <div onClick={() => onDelete(img)} className={[styles.button, styles.delete].join("")}>
              <i className="material-icons">close</i>
            </div>
          </div>
        ))}

        <div
          onClick={onUploadClick}
          className={[styles.imageWrapper, isLarge ? styles.large : null].join(" ")}
        >
          <input
            type="file"
            accept={allowedFileExtensions}
            id={"mm-admin-image-upload" + experience.id}
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) onUploadFileSelected(e.target.files[0])
            }}
          />
          <div className={styles.uploadButton}>
            <i className="material-icons">add</i>
          </div>
        </div>
        <div className={styles.filler} />
      </div>
    </div>
  )
}

export default ExperienceImageEditor
