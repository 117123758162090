import React, { useContext } from "react"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import { MMEvent, MMExperience } from "../../../types"
import { format } from "date-fns"
import { hu } from "date-fns/locale"
import styles from "./OrderEvent.module.scss"
import MMTooltip from "src/common-components/MMTooltip/MMTooltip"

interface Props {
  event: MMEvent
  experience: MMExperience
  onSelectClick: () => void
}

const OrderEvent: React.FC<Props> = ({ event, experience, onSelectClick }) => {
  const { numOfGuests } = useContext(GlobalContext)
  const startDate = new Date(event.startTime * 1000)
  const endDate = new Date((event.startTime + experience.lengthInHours * 3600) * 1000)

  return (
    <div className={styles.container}>
      <div className={styles.data}>
        <p>{new Date(event.startTime * 1000).toLocaleDateString("hu", { dateStyle: "long" })}</p>
        <p>
          {startDate.toLocaleTimeString("hu", { timeStyle: "short" })} -{" "}
          {endDate.toLocaleTimeString("hu", { timeStyle: "short" })}
        </p>
      </div>
      {numOfGuests === null ? (
        <MMTooltip arrow placement="top" title="Adja meg a vendégek számát!">
          <div>
            <MMButton disabled handleClick={onSelectClick} theme="callToAction">
              Foglalás
            </MMButton>
          </div>
        </MMTooltip>
      ) : (
        <MMButton handleClick={onSelectClick} theme="callToAction">
          Foglalás
        </MMButton>
      )}
    </div>
  )
}
export default OrderEvent
