import React, { useState } from "react"
import { STATIC_URL } from "src/constants"
import styles from "./ExperienceGalleryImage.module.scss"

interface Props {
  imageName: string
  onClick?: () => void
}

const ExperienceGalleryImage: React.FC<Props> = ({ imageName, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const isDummy = imageName === undefined
  return (
    <div className={styles.image} onClick={onClick}>
      {isDummy ? null : (
        <img
          onLoad={() => setTimeout(() => setImageLoaded(true), 50 + Math.random() * 100)}
          src={STATIC_URL + imageName}
        />
      )}

      <div
        className={`${styles.loader} ${imageLoaded ? styles.hidden : ""} ${
          isDummy ? styles.dummy : ""
        }`}
      >
        <img src={"/logo/logo-bird.svg"} className={styles.icon} />
        {isDummy ? <p>A kép nem elérhető</p> : null}
      </div>
    </div>
  )
}

export default ExperienceGalleryImage
