import { format } from "date-fns"
import { hu } from "date-fns/locale"
import React, { useContext, useState } from "react"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import { MMEvent } from "../../../types"
import FinishOrderWrapper from "./FinishOrder/FinishOrderWrapper"
import styles from "./OrderSummary.module.scss"

interface Props {
  setTransformed?: (val: boolean) => void
  selectedEvent: MMEvent | null
  price: number
  isOpen: boolean
  onFinished: () => void
}

const OrderSummary: React.FC<Props> = ({
  setTransformed,
  selectedEvent,
  price,
  isOpen,
  onFinished,
}) => {
  const { numOfGuests } = useContext(GlobalContext)
  const [finishPopupOpen, setFinishPopupOpen] = useState(false)

  if (!selectedEvent) {
    setTransformed?.(false)
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.top}>
          <p>Foglalás</p>
        </div>
      </div>
      <div className={styles.dataBlock}>
        <p className={styles.point}>Választott időpont:</p>
        <p className={styles.date}>
          {format(new Date(selectedEvent.startTime * 1000), "PPpp", { locale: hu })}
        </p>
      </div>
      <div className={styles.dataBlock}>
        <span className={styles.point}>Vendégek száma:</span> <span>{numOfGuests} fő</span>
      </div>
      <div className={styles.dataBlock}>
        <span className={styles.point}>Fizetendő összeg:</span>{" "}
        <span>{(numOfGuests || 0) * price} Ft</span>
      </div>
      {isOpen ? (
        <div className={styles.info}>
          <p>Információ</p>
          <p>
            A foglalás után e-mailben elküldjük a fizetéshez, illetve a programhoz kapcsolódó egyéb
            részleteket. Az e-mailben található linkre kattintva lehet véglegesíteni a foglalást, a
            rendszerbe csak ekkor kerül be. <br />
            <br /> Felmerülő kérdésekkel vagy problémákkal kapcsolatban az{" "}
            <a>info@marminalunk.hu</a> email címen vagyunk elérhetőek.
          </p>
        </div>
      ) : null}
      <div className={styles.buttons}>
        <MMButton theme="callToAction" handleClick={() => setFinishPopupOpen(true)}>
          Fizetés
        </MMButton>
        <MMButton handleClick={() => setTransformed?.(false)}>Vissza</MMButton>
      </div>
      <FinishOrderWrapper
        onFinished={onFinished}
        eventID={selectedEvent.id}
        open={finishPopupOpen}
        setOpen={setFinishPopupOpen}
      />
    </div>
  )
}
export default OrderSummary
