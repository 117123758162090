import React from "react"
import styles from "./RatingDisplay.module.scss"

interface Props {
	score: number
	setScore?: (score: number) => void
	size?: "big" | "small" | "tiny"
}

const RatingDisplay: React.FC<Props> = ({ score, setScore, size = "small" }) => {
	const handleClick = (score: number) => {
		setScore?.(score)
	}

	const stars = Array.from(Array(5).keys()).map((number, index) => {
		const filled = number <= score - 1
		return (
			<i
				key={index}
				onClick={() => handleClick(index + 1)}
				className={[
					"material-icons",
					filled ? styles.filled : "",
					setScore ? styles.editing : "",
					styles[size],
				].join(" ")}
			>
				{filled ? "star" : "star_border"}
			</i>
		)
	})

	return <div className={styles.container}>{stars}</div>
}
export default RatingDisplay
