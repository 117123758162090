import React, { useContext, useState } from "react"
import MMButton from "../../../common-components/MMButton/MMButton"
import styles from "./Login.module.scss"
import { ReactComponent as GoogleLogo } from "../../../assets/google-logo.svg"
import { ReactComponent as MMLogo } from "../../../assets/mm-logo-bird.svg"
import { SlideLayoutChildProps } from "../../../types"
import MMInput from "../../../common-components/MMInput/MMInput"
import { login } from "../../../apicalls/login"
import { validateEmail } from "../../../utils/validations"
import { GlobalContext } from "../../../App"
import { useGoogleLogin } from "react-google-login"
import { loginGoogle } from "../../../apicalls/loginGoogle"
import Loader from "../../../common-components/Loader/Loader"
import { loginFacebook } from "../../../apicalls/loginFacebook"
import { FBloginURI, GoogleClientID } from "../../../constants"

const facebookLogin = async (tokenCallback: (token: string) => void) => {
  window.open(
    FBloginURI,
    "Facebook login",
    "left=100, top=100, resizable=no, scrollbar=no, menubar=no, location=no, directories=no, status=no, height=450, width=650"
  )
  ;(window as any).fbMMLoginCallback = tokenCallback
}

interface LoginErrors {
  "invalid credentials"?: boolean
  "invalid email"?: boolean
}

interface Props extends SlideLayoutChildProps {
  closeModal: () => void
}

const Login: React.FC<Props> = ({ setTransformed, closeModal }) => {
  const { setUser } = useContext(GlobalContext)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState<LoginErrors | null>(null)
  const [loading, setLoading] = useState(false)

  const { signIn } = useGoogleLogin({
    clientId: GoogleClientID,
    onSuccess: async (googleResponse: any) => {
      setLoading(true)
      const res = await loginGoogle(googleResponse.tokenId)
      if (res.result === "ok") {
        setUser(res.data)
        closeModal()
        return
      }
      //TODO: handle error
    },
    onFailure: (error: any) => {
      console.log("Google error:", JSON.stringify(error))
    },
  })

  const fbTokenCallback = async (token: string) => {
    setLoading(true)
    const res = await loginFacebook(token)
    if (res.status !== 200) {
      //TODO: show error noti
      return
    }
    if (res.data.result === "ok") {
      setUser(res.data.data)
      closeModal()
      return
    }
  }

  const handleLogin = async () => {
    setErrors(null)

    if (!validateEmail(email)) {
      setErrors({ "invalid email": true })
      return
    }

    const res = await login({ email, password, persistent: false })

    if (res.result === "error") {
      if (res.data === "invalid credentials") {
        setErrors({ "invalid credentials": true })
        return
      } else {
      } //TODO: context error popup
      return
    }

    console.log(res.data)
    setUser(res.data)
    closeModal()
  }

  if (loading) return <Loader width="5rem" />

  return (
    <div className={styles.container}>
      <MMInput
        value={email}
        setValue={setEmail}
        label="E-mail"
        icon="alternate_email"
        className={styles.input}
        name="email"
        errorText={errors && errors["invalid email"] ? "Helytelen e-mail cím" : undefined}
      />
      <MMInput
        value={password}
        setValue={setPassword}
        label="Jelszó"
        icon="lock"
        className={styles.input}
        name="password"
        type="password"
      />
      <MMButton theme="callToAction" handleClick={handleLogin}>
        Bejelentkezés
      </MMButton>
      <hr className={styles.texthr} content-text={"Még nincs fiókja?"} />
      <MMButton
        handleClick={() => setTransformed?.(true)}
        theme="default"
        className={styles.bottomMargin}
      >
        <div className={styles.buttonContent}>
          <MMLogo className={[styles.logo, styles.mmLogo].join(" ")} />
          Új fiók létrehozása
        </div>
      </MMButton>
      <MMButton
        theme="default"
        className={styles.bottomMargin}
        icon="facebook"
        iconClassName={styles.facebookLogo}
        logoAbsolute
        handleClick={() => facebookLogin(fbTokenCallback)}
      >
        Folytatás Facebook fiókkal
      </MMButton>
      <MMButton theme="default" handleClick={signIn}>
        <div className={styles.buttonContent}>
          <GoogleLogo className={[styles.logo, styles.googleLogo].join(" ")} />
          Folytatás Google fiókkal
        </div>
      </MMButton>
    </div>
  )
}
export default Login
